import { Component } from 'react';
import { Link } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import Axios from 'axios';

// project imports
import { getSellerUrl, getSellerHeaders } from 'shared/http';
import { LocationDefaults, LocationValidators, LocationNonRequiredFields } from 'features/classes';
import { mobileNumberFormat } from 'shared/modifiers';
import { locationadd } from 'shared/settings/apiconfig';
import { ToasterMessage } from 'shared/toast/toast';
import Validators from 'shared/validators';
import { BUYER_URL } from 'shared/settings/config';
import { DebounceInput } from 'react-debounce-input';

// style imports
import './LocationEdit.scss';
class LocationAdd extends Component {

    locationData;
    timeZoneList;
    stateList;

    constructor(props) {
        super(props);

        this.state = {
            viewOnly: false,
            formValid: false,
            formControls: {},
            addOnly: false,
            city: [],
            pageLoading: true,
            locationFormLoading: false,
            locationStatus: { show: false, type: '', message: '' },
            ispickup: '',
            isdelivery: '',
            isshipping: '',
            isautodebit: false,
            stateCityError: '',
            cityStateIsValid: true,
            cityDropdownValue: [],
            showCityDropdown: false,
            irmValid: false,
            irmValidCheck: false
        };
        this.locationData = new LocationDefaults();
        this.timeZoneList = [];
        this.stateList = [];
    }

    componentDidMount() {
        const formControls = {};

        Object.entries(this.locationData).forEach(([key, value]) => {
            formControls[key] = {
                value: value,
                error: '',
                validators: LocationValidators[key]
            };
        });

        this.setState({
            formControls,
            pageLoading: false,
            formValid: false
        }, () => {
            console.log(this.state.formControls);
        });
    }

    onInputValidation = async (event) => {

        if (event.target.name === 'zip' || event.target.name === 'phone') {
            event.target.value = event.target.value.replace(/[^0-9]/g, '')
        }

        this.setState(
            {
                ...Validators.formValidation({ ...this.state.formControls }, event)
            });
    };


    onSaveLocation = async (event) => {
        event.preventDefault();
        const data = {};

        if (!this.state.locationFormLoading) {
            this.setState({ locationFormLoading: true });
            data['title'] = this.state.formControls.title.value;
            data['description'] = this.state.formControls.description.value;
            data['contactname'] = this.state.formControls.contactname.value;
            data['email'] = this.state.formControls.email.value;
            data['phone'] = this.state.formControls.phone.value;
            data['about'] = this.state.formControls.about.value;
            data['website'] = this.state.formControls.website.value;
            data['city'] = this.state.formControls.city.value;
            data['state'] = this.state.formControls.state.value;
            data['zip'] = this.state.formControls.zip.value;
            data['timezone'] = '6';
            data['country'] = 'India'
            data['status'] = 'Active';
            data['locationstatecode'] = '2';
            data['locationcode'] = '2';

            await Axios.post(getSellerUrl(locationadd), JSON.stringify({ data: data }), getSellerHeaders())
                .then((response) => {
                    this.setState({
                        locationStatus: { show: true, type: 'success', message: 'Saved' }
                    }, () => {
                        setTimeout(() => {
                            this.setState({
                                locationFormLoading: false,
                                locationStatus: { show: false, type: '', message: '' }
                            });
                            this.props.history.push({ pathname: '/locations', state: this.props.location.state });
                        }, 3000);
                    });
                })
                .catch((error) => {
                    this.setState({
                        locationFormLoading: false,
                        locationStatus: { show: true, type: 'danger', message: error.response.data['message'][0].message }
                    });
                });
        }

    };

    navigateToList = () => {
        this.props.history.push({ pathname: '/locations', state: this.props.location.state });
    };
    render() {
        return (
            <div className="d-flex flex-column location-edit-content">
                <div className="d-flex flex-row align-items-center border-bottom border-outline" style={{ minHeight: '40px' }}>
                    <div className="d-flex flex-row align-items-center justify-content-center border-right border-outline px-2 h-100">
                        <span onClick={this.navigateToList} className="clickable">
                            <i className="fa fa-chevron-circle-left fa-lg text-back rounded-circle"></i>
                            <span className="ml-2">Back</span>
                        </span>
                    </div>
                    <span className="text-secondary mx-2">Create New Location</span>
                </div>
                {
                    this.state.pageLoading ?
                        <div className="d-flex flex-column align-items-center justify-content-center w-100 h-100">
                            <img src={`${process.env.PUBLIC_URL}/static/icons/table/option_1.gif`} alt="default" height="100px" width="100px" />
                        </div> :
                        <Form className="d-flex flex-column align-items-center justify-content-center border m-3 p-2" onSubmit={this.onSaveLocation}>
                            <div className="d-flex flex-column flex-sm-row" style={{ width: '40%', marginBottom: '70px' }}>

                                <div className="d-flex flex-column flex-fill mx-lg-5">
                                    <span className="text-secondary">Location Details</span>
                                    <div className="d-flex flex-column" >




                                        <div className="float-control">
                                            <div className="text">
                                                {
                                                    this.state.formControls.title.error ? <small className="error">{this.state.formControls.title.error}</small> : null
                                                }
                                            </div>
                                            <Form.Control type="text" id="location-title" className={`${this.state.formControls.title.error ? 'error-control' : ''} ${this.state.formControls.title.value ? 'form-control-active' : ''}`} name="title" value={this.state.formControls.title.value} disabled={this.state.viewOnly} placeholder=" " onChange={(event) => this.onInputValidation(event)} />
                                            <label htmlFor="location-title">Location Title *</label>
                                        </div>

                                        <div className="float-control">
                                            <div className="text">
                                                {
                                                    this.state.formControls.description.error ? <small className="error">{this.state.formControls.description.error}</small> : null
                                                }
                                            </div>
                                            <Form.Control type="text" id="brief-description" className={`${this.state.formControls.description.error ? 'error-control' : ''} ${this.state.formControls.description.value ? 'form-control-active' : ''}`} name="description" value={this.state.formControls.description.value} disabled={this.state.viewOnly} placeholder=" " onChange={(event) => this.onInputValidation(event)} />
                                            <label htmlFor="brief-description">Brief Description *</label>
                                        </div>

                                        <div className="float-control">
                                            <div className="text">
                                                {
                                                    this.state.formControls.contactname.error ? <small className="error">{this.state.formControls.contactname.error}</small> : null
                                                }
                                            </div>
                                            <Form.Control type="text" id="contact-name" className={`${this.state.formControls.contactname.error ? 'error-control' : ''} ${this.state.formControls.contactname.value ? 'form-control-active' : ''}`} name="contactname" value={this.state.formControls.contactname.value} disabled={this.state.viewOnly} placeholder=" " onChange={(event) => this.onInputValidation(event)} />
                                            <label htmlFor="contact-name">Contact Name *</label>
                                        </div>

                                        <div className="float-control">
                                            <div className="text">
                                                {
                                                    this.state.formControls.email.error ? <small className="error">{this.state.formControls.email.error}</small> : null
                                                }
                                            </div>
                                            <Form.Control type="text" id="location-email" className={`${this.state.formControls.email.error ? 'error-control' : ''} ${this.state.formControls.email.value ? 'form-control-active' : ''}`} name="email" value={this.state.formControls.email.value} disabled={this.state.viewOnly} placeholder=" " onChange={(event) => this.onInputValidation(event)} />
                                            <label htmlFor="location-email">Location Email *</label>
                                        </div>

                                        <div className="float-control">
                                            <div className="text">
                                                {
                                                    this.state.formControls.phone.error ? <small className="error">{this.state.formControls.phone.error}</small> : null
                                                }
                                            </div>
                                            <Form.Control type="text" id="location-phone" className={`${this.state.formControls.phone.error ? 'error-control' : ''} ${this.state.formControls.phone.value ? 'form-control-active' : ''}`} name="phone" value={this.state.formControls.phone.value} disabled={this.state.viewOnly} placeholder=" " maxLength={10} onChange={(event) => this.onInputValidation(event)} />
                                            <label htmlFor="location-phone">Location Phone *</label>
                                        </div>

                                        <div className="float-control">
                                            <div className="text">
                                                {
                                                    this.state.formControls.website.error ? <small className="error">{this.state.formControls.website.error}</small> : null
                                                }
                                            </div>
                                            <Form.Control type="text" id="external-website" className={`${this.state.formControls.website.error ? 'error-control' : ''} ${this.state.formControls.website.value ? 'form-control-active' : ''}`} name="website" value={this.state.formControls.website.value} disabled={this.state.viewOnly} placeholder=" " onChange={(event) => this.onInputValidation(event)} />
                                            <label htmlFor="external-website">External Website *</label>
                                        </div>

                                        <div className="float-control">
                                            <div className="text">
                                                {
                                                    this.state.formControls.state.error ? <small className="error">{this.state.formControls.state.error}</small> : null
                                                }
                                            </div>
                                            <Form.Control as="select" className={`${this.state.formControls.state.value ? 'form-control-active' : 'text-muted'} ${this.state.formControls.state.error ? 'error-control' : ''}`} id="state" name="state" value={this.state.formControls.state.value} disabled={this.state.viewOnly} placeholder=" " onChange={(event) => this.onInputValidation(event)} >
                                                <option style={{ display: "none" }} selected>Select State</option>
                                                <option value='Tamil Nadu'>Tamil Nadu</option>
                                            </Form.Control>
                                            <label htmlFor="state">State *</label>
                                        </div>

                                        <div className="float-control" style={{ paddingBottom: `${this.state.showCityDropdown ? '2px' : ''}` }}>

                                            <DebounceInput type="text" className={`form-control ${this.state.formControls.city.value ? 'form-control-active' : 'text-muted'} ${this.state.formControls.city.error ? 'error-control' : ''}`} id="city" name="city" value={this.state.formControls.city.value} disabled={this.state.viewOnly} placeholder=" " style={{ textTransform: "capitalize" }} onChange={(event) => this.onInputValidation(event)} debounceTimeout={200} />
                                            <label htmlFor="city">City *</label>
                                        </div>
                                        {this.state.showCityDropdown ? <div className='location-city-value'>
                                            {this.state.cityDropdownValue.map((elem, index) => (
                                                <div className='pd-15' id={index} onClick={() => { this.setCityValue(elem.city) }}>{elem.city}</div>
                                            ))}
                                        </div> : ""}
                                        <div className="float-control">
                                            <div className="text">
                                                {
                                                    this.state.formControls.zip.error ? <small className="error">{this.state.formControls.zip.error}</small> : null
                                                }
                                            </div>
                                            <Form.Control type="text" id="zip" maxLength={5} className={`${this.state.formControls.zip.error ? 'error-control' : ''} ${this.state.formControls.zip.value ? 'form-control-active' : ''}`} name="zip" value={this.state.formControls.zip.value} disabled={this.state.viewOnly} placeholder=" " onChange={(event) => this.onInputValidation(event)} />
                                            <label htmlFor="zip">ZIP *</label>
                                        </div>

                                        <div className="float-control">
                                            <div className="text">
                                                {
                                                    this.state.formControls.about.error ? <small className="error">{this.state.formControls.about.error}</small> : null
                                                }
                                            </div>
                                            <Form.Control as="textarea" row="3" id="about" className={`${this.state.formControls.about.error ? 'error-control' : ''} ${this.state.formControls.about.value ? 'form-control-active' : ''}`} name="about" value={this.state.formControls.about.value} disabled={this.state.viewOnly} placeholder=" " onChange={(event) => this.onInputValidation(event)} />
                                            <label htmlFor="about">About *</label>
                                        </div>

                                        <div id="toaster" className={this.state.locationStatus.show ? "d-flex flex-row align-items-center justify-content-center toaster " : ''}>
                                        {
                                            this.state.locationStatus.show ?
                                                <ToasterMessage type={this.state.locationStatus.type} message={this.state.locationStatus.message} className="alert-handle" />
                                                : this.state.stateCityError ? <small className='text-danger d-flex align-items-center justify-content-center' style={{ textAlign: 'center' }}>{this.state.stateCityError}</small> : null
                                        }
                                    </div>
                                    <div className="d-flex flex-row align-items-center justify-content-center mb-3">
                                        <Link to="/locations">
                                            <Button variant="outline-dark mx-1" style={{ width: '90px', color: 'white' }}>Cancel</Button>
                                        </Link>
                                        <Button type="submit" disabled={!this.state.formValid} variant="secondary mx-1" style={{ width: '90px', color: 'white' }}>Save</Button>
                                    </div>
                                    </div>
                                </div>
                                <div className="align-items-center justify-content-center mb-3" style={{ marginTop: "-4rem" }}>
                                    
                                </div>


                            </div>

                        </Form>
                }
            </div>
        );
    }
}

export default connect(state => state)(LocationAdd);
