import { Component } from 'react';
import { Link } from 'react-router-dom';
import { Form, Button, Spinner } from 'react-bootstrap';
import axios from 'axios';

// project imports
import { ACCESS_TOKEN, REFRESH_TOKEN } from 'shared/settings/token';
import { getSellerUrl, getSellerHeaders } from 'shared/http';
import { login } from 'shared/settings/auth';
import { authtoken } from 'shared/settings/apiconfig';
import Validators from 'shared/validators';
// import toast from 'shared/toast/';
import { ToasterMessage } from 'shared/toast/toast';
import store from 'features/store';

// style imports
import './Signin.scss';

/**
 * make authentic user to enter the system
 * 
**/

export class Signin extends Component {

    constructor(props) {
        super(props);

        this.state = {
            // toggle input type
            showPassword: false,
            signinValid: false,

            // form
            formValid: false,
            formControls: {
                email: { value: '', valid: false, error: '', validators: [Validators.required(), Validators.email(), Validators.maxLength(60)] },
                password: { value: '', valid: false, error: '', validators: [Validators.required()] }
            },
            remember: false,

            // loading
            signinFormLoading: false,

            //status
            signinStatus: { show: false, type: '', message: '' }
        };
    }

    componentDidMount() {
        this.setState({
            signinStatus: { show: this.props.location.state && this.props.location.state.message ? true : false, type: this.props.location.state && this.props.location.state.type ? this.props.location.state.type : '', message: this.props.location.state && this.props.location.state.message ? this.props.location.state.message : '' }
        }, () => {
            setTimeout(() => {
                this.setState({
                    signinStatus: { show: false, type: '', message: '' }
                });
            }, 3000);
        })
    }

    /* toggles password to show or hide on clicking eye icon */
    togglePassword = () => {
        this.setState({ showPassword: !this.state.showPassword });
    };

    /* checks whether the input is valid or not */
    onInputValidation = (event) => {
        let controlError = '';

        /* checks all the validators for the control */
        for (let validator of this.state.formControls[event.target.name].validators) {
            controlError = validator(event.target.value);
            if (controlError) break;
        }

        /* sets the state of the control and form validity */
        this.setState({
            formControls: {
                ...this.state.formControls,
                [event.target.name]: {
                    ...this.state.formControls[event.target.name],
                    value: event.target.value,
                    valid: !Boolean(controlError),
                    error: controlError
                }
            }
        },
            () => {
                /* check form validity */
                if (controlError)
                    this.setState({ formValid: false });
                else {
                    let formValid = true;
                    for (let control of Object.keys(this.state.formControls)) {
                        if (!this.state.formControls[control].valid) {
                            formValid = false;
                            break;
                        }
                    }
                    this.setState({ formValid: formValid });
                }
            });
    }

    /* get longer token validity */
    keepMeSignin = (event) => {
        this.setState({ remember: event.target.checked });
    };
    termsAndAgreement = (event) => {
        this.setState({ signinValid: event.target.checked });
    }

    /* called when signin button is clicked */
    onSignin = async (event) => {
        event.preventDefault();
        if (this.state.formValid) {
            const data = {
                data: {
                    username: this.state.formControls.email.value,
                    password: btoa(this.state.formControls.password.value),
                    'keep-user-logged-in': this.state.remember
                }
            };
            this.setState({ signinFormLoading: true });

            /* api call to get user token */
            await axios.post(getSellerUrl(authtoken), JSON.stringify(data), getSellerHeaders(false))
                .then((response) => {
                    const jsonData = response.data;
                    login({ [ACCESS_TOKEN]: jsonData['accessToken'], [REFRESH_TOKEN]: jsonData['refreshToken'] });
                    this.setState({ signinFormLoading: false });
                    let value = store.getState()['urlNavigate']
                    if (value === '/') {
                        this.props.history.push('/home');
                    }
                    else {
                        this.props.history.push(value);
                    }
                })
                .catch((error) => {
                    // this.setState({ signinFormLoading: false });
                    if (error.response?.data['message']?.search('Unauthorized') !== -1) {
                        // toast.error("Sorry, we couldn't find an account with that email and password combination. Please try again.")
                        this.setState({
                            signinFormLoading: false,
                            signinStatus: { show: true, type: 'danger', message: "Sorry, we couldn't find an account with that email and password combination. Please try again." }
                        });
                    }
                    else
                        // toast.error(error.response.data['message'] || 'Failed to reach server');
                        this.setState({
                            signinFormLoading: false,
                            signinStatus: { show: true, type: 'danger', message: error.response.data['message'] || 'Failed to reach server' }
                        });
                });
        }
    }

    render() {

        return (
            <>

                <div className="d-flex align-items-center  signin">
                    <div className="d-flex align-items-center justify-content-center mt-5" style={{ width: '50%', backgroundColor: 'white', height: "100%" }}>
                        <img src={`${process.env.PUBLIC_URL}/static/signin.png`} style={{ width: '100%', height: "100%" }} alt="default" />

                    </div>
                    <div className="d-flex align-items-center justify-content-center  signin" style={{ width: '50%' }}>

                        <div className="d-flex flex-column justify-content-center signin-card px-3 px-md-4 px-lg-5">
                            <div className="d-flex justify-content-center my-4">
                                <img src={`${process.env.PUBLIC_URL}/static/logo/GoYelagiri.png`} alt="logo" height="80px" width="190px" />
                            </div>
                            <div className="my-4">
                                <span className="title"><b>Sign In</b></span>
                                <Form className="d-flex flex-column justify-content-center my-3 px-2" onSubmit={(event) => this.onSignin(event)}>
                                    <div className="float-control">
                                        <div className="text">
                                            {
                                                this.state.formControls.email.valid ? null : <small className="error">{this.state.formControls.email.error}</small>
                                            }
                                        </div>
                                        <Form.Control autoFocus type="email" id="signin-email" name="email" value={this.state.formControls.email.value} placeholder=" " onChange={(event) => this.onInputValidation(event)} />
                                        <label htmlFor="signin-email">Email</label>
                                    </div>

                                    <div className="float-control">
                                        {
                                            this.state.showPassword ?
                                                <i className="password-icon fa fa-eye fa-flip-horizontal" onClick={this.togglePassword} /> :
                                                <i className="password-icon fa fa-eye-slash fa-flip-horizontal" onClick={this.togglePassword} />
                                        }
                                        <div className="text">
                                            {
                                                this.state.formControls.password.valid ? null : <small className="error">{this.state.formControls.password.error}</small>
                                            }
                                        </div>
                                        <Form.Control type={this.state.showPassword ? 'text' : 'password'} id="signin-password" name="password" value={this.state.formControls.password.value} placeholder=" " onChange={(event) => this.onInputValidation(event)} />
                                        <label htmlFor="signin-password">Password</label>
                                    </div>

                                    {/* <Form.Check className="my-3" type="checkbox" id="signin-checkbox" label="Keep me signed in" value={this.state.remember} onChange={this.keepMeSignin} /> */}

                                    <div>
                                        <div className="d-flex flex-row">
                                            {
                                                this.state.signinStatus.show ?
                                                    <ToasterMessage type={this.state.signinStatus.type} message={this.state.signinStatus.message} className="alert-handle mt-2"></ToasterMessage>
                                                    : null
                                            }
                                        </div>
                                        <div className="text-center mt-3 justify-content-between my-2">
                                            <div className="align-items-center">
                                                <Button variant="secondary" style={{ cursor: this.state.formValid ? 'pointer' : 'not-allowed', width: '50%', color: 'white' }} type="submit"
                                                    disabled={!this.state.formValid || this.state.signinFormLoading   }>Sign In {  this.state.signinFormLoading ?<Spinner animation="border" variant="info" style={{ margin: '0 8px' }} /> :  null  }</Button>
                                            </div>
                                            <div className=" mt-3 align-items-center">
                                                <Link to="/forgot-password">
                                                    <small className="text-secondary" style={{ fontSize: "14px" }}>Forgot Password?</small>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </div>

                    </div>
                </div>
            </>

        );
    }
}