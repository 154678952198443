export const sellerbaseurl ='https://goyelagiri.com/services';
export const buyerbaseurl = 'https://invcbuyer.ctbids.com/services';
export const paymentbaseurl = 'https://paymentsccpdev.ctbids.com/services';
export const reportsbaseurl = 'https://reportsuat.ctbids.com/services';

// url to alert sse
export const alertsseurl = 'https://alertssse.ctbids.com/services/';

//url to redirect to buyer for old invoices
export const buyerlinkurl = 'https://buyeruat.ctbids.com/estate-sale/';

//seller apis
export const locationlist = '/api/v1/location/list';
export const locationdetail = '/api/v1/location/detail';
export const locationupdate = '/api/v1/location/update';

export const loctemplatelist = '/api/v1/location/template/list';
export const loctemplateadd = '/api/v1/location/template/add';
export const loctemplateupdate = '/api/v1/location/template/update';
export const loctemplatedetail = '/api/v1/location/template/detail';
export const loctemplatetype = '/api/v1/location/template/default';

export const locimagelist = '/api/v1/location/image/list';
export const locimagedetail = '/api/v1/location/image/detail';
export const locimageadd = '/api/v1/location/image/add';
export const locimageupdate = '/api/v1/location/image/update';
export const locationadd = '/api/v1/location/add';


export const invoicePrintPdf = '/api/v1/invoice/pdf/view';
export const categorylist = '/api/v1/category/list';
export const categoryadd = '/api/v1/category/add';
export const categoryupdate = '/api/v1/category/update';
export const categorynewlist = '/api/v1/category/new/list';
export const roomslist = '/api/v1/category/rooms/list';
export const roomsMappingList = '/api/v1/category/selection/list';

export const itemlist = '/api/v1/item/list';
export const itemdetail = '/api/v1/item/detail';
export const itemadd = '/api/v1/item/add';
export const itemupdate = '/api/v1/item/update';
export const itemclone = '/api/v1/item/clone';
export const itemdelete = '/api/v1/item/delete';
export const itemsync = '/api/v1/sync/sale';
export const itemcloseorder = '/api/v1/item/bulk/update'

export const skugenerate = '/api/v1/item/generate/SKU';
export const createdbyList = '/api/v1/item/createBy'

export const itemimagelist = '/api/v1/item/image/list';
export const itemimagedetail = '/api/v1/item/image/detail';
export const itemimageadd = '/api/v1/item/image/add';
export const itemimageupdate = '/api/v1/item/image/update';
export const itemimagedelete = '/api/v1/item/image/delete';

export const itemnotelist = '/api/v1/item/note/list';
export const itemnotedetail = '/api/v1/item/note/detail';
export const itemnoteadd = '/api/v1/item/note/add';

export const buyernotelist = '/api/v1/buyer/user/note/list';
export const buyernotedetail = '/api/v1/buyer/user/note/detail';
export const buyernoteadd = '/api/v1/buyer/user/note/add';

export const salelist = '/api/v1/sale/list';
export const saledetail = '/api/v1/sale/detail';
export const salebylocation = '/api/v1/sale/at/location';
export const saleadd = '/api/v1/sale/add';
export const saleupdate = '/api/v1/sale/update';
export const salestaffcheckpoint = '/api/v1/sale/checkpoint/list'

export const checkitemtaxzero = '/api/v1/invoice/zerotaxsale/'

export const itemstatusbysale = '/api/v1/sale/item/count/groupedby/statusandtype';
export const itemstatusbulkupdate = '/api/v1/item/status/bulk/update';

export const saleimagelist = '/api/v1/sale/image/list';
export const saleimagedetail = '/api/v1/sale/image/detail';
export const saleimageadd = '/api/v1/sale/image/add';
export const saleimageupdate = '/api/v1/sale/image/update';

export const salenotelist = '/api/v1/sale/note/list';
export const salenotedetail = '/api/v1/sale/note/detail';
export const salenoteadd = '/api/v1/sale/note/add';

export const timezonelist = '/api/v1/timezone/list';

export const statecitybyzipcode = '/api/v1/address/zipcode/detail';
export const statelist = '/api/v1/address/state/list';
export const citylist = '/api/v1/address/city/list';

export const authtoken = '/api/v1/auth/token';
export const tokenrefresh = '/api/v1/token/refresh';

export const roleadd = '/api/v1/role/add';
export const roleupdate = '/api/v1/role/update';
export const roledetail = '/api/v1/role/detail';
export const rolelist = '/api/v1/role/list';

export const selleradd = '/api/v1/user/add';
export const sellerupdate = '/api/v1/user/update';
export const sellerdetail = '/api/v1/user/detail';
export const sellerlist = '/api/v1/user/list';
export const staffdelete = '/api/v1/user/remove/';
export const saledelete = '/api/v1/sale/delete/';

export const passwordreset = '/api/v1/user/reset/password';
export const activatereset = '/api/v1/user/activate/password/set';
export const passwordset = '/api/v1/user/password/set';
export const changepassword = '/api/v1/user/password/change';
export const activate = '/api/v1/user/activate/account';

export const permission = '/api/v1/user/permission/list';

export const changeemail = '/api/v1/user/email/change';
export const verifyemail = '/api/v1/user/email/change/verify';
export const resendverifyemail = '/api/v1/user/email/change/verification/request';
export const changemobile = '/api/v1/user/mobile/change';
export const verifymobile = '/api/v1/user/mobile/change/verify';
export const resendverifymobile = '/api/v1/user/mobile/change/verification/request';

export const fileUpload = '/api/v1/file/upload';
export const fileUploadFragment = '/api/v1/file/upload/fragment';

export const invoiceList = '/api/v1/invoice/list';
export const invoiceDetail = '/api/v1/invoice/detail';
export const invoiceSendEmail = '/api/v1/invoice/pdf/send/email';
export const generateInvoice = '/api/v1/invoice/generate';
export const updateInvoice = '/api/v1/invoice/add';
export const transactionHistory = "/api/v1/payment/transaction/list";
export const buyerListInvoice = "/api/v1/buyer/completed/auction/bidder/detail/";
export const createNewInvoice = "/api/v1/invoice/create";
export const createChildInvoice = "/api/v1/invoice/create/addon";
export const invoiceitemreceiptmethod = '/api/v1/invoice/item/receipt'
export const bidderIrm = '/api/v1/bidder/irm/detail'


export const buyersuspendcount = '/api/v1/buyer/user/suspension/unsuspension/'
export const buyerlist = '/api/v1/buyer/all/list';
export const buyerdetail = '/api/v1/buyer/detail';
export const buyerdetailview = '/api/v1/buyer/detail/view';
export const buyersuspend = '/api/v1/buyer/suspend';
export const buyersuspendlist = '/api/v1/buyer/suspend/list';
export const buyerunsuspend = '/api/v1/buyer/suspend/revoke';
export const buyersuspendalllist = '/api/v1/buyer/suspend/buyer/list';
export const buyerunsuspendalllist = '/api/v1/buyer/unsuspend/buyer/list';

export const taxexemptfilelist = '/api/v1/tax/exempt/file/list';
export const taxexemptlist = '/api/v1/tax/exempt/list';
export const taxexemptadd = '/api/v1/tax/exempt/add';
export const taxexemptupdate = '/api/v1/tax/exempt/update';
export const taxexemptionpending = "/api/v1/buyer/user/taxexempt/pending/list";
export const taxexemptverify = '/api/v1/buyer/tax/exempt/verify/list';
export const taxexemptverifyadd = '/api/v1/buyer/tax/exempt/verify/add';
export const taxexemptstatus = '/api/v1/buyer/tax/exempt/status';
export const taxexemptlocation = '/api/v1/tax/exempt/list/location';
export const taxexemptbuyer = '/api/v1/tax/exempt/list/buyer/';
export const token = '/api/v1/buyer/connect/token';
export const secret_key = '/api/v1/seller/buyer/token';
export const bid_history = '/api/v1/buyer/auction/bid/history';
export const bid_history_closedItem = '/api/v1/buyer/auction/bid/history/closed/item';
export const bidders_list = '/api/v1/buyer/completed/auction/bidders/list/';
export const change_bidder = '/api/v1/buyer/completed/auction/change/bidder';
export const taxexemptfiledelete = '/api/v1/tax/exempt/file/delete';
export const taxexemptfileapprove = '/api/v1/tax/exempt/file/update';
export const SaledDahboardgenerateInvoice = '/api/v1/ctbids/sale/invoice/generate/';
export const updateShipping = '/api/v1/invoice/shipping/update';
export const transactionAdd = '/api/v1/payment/transaction/add';
export const bidRetract = '/api/v1/buyer/auction/bid/retract';
export const bidRetractAll = '/api/v1/buyer/auction/bid/retract/all';
export const bidRetractSelected = '/api/v1/buyer/auction/bidder/bid/retract';
export const generateEmail = '/api/v1/invoice/pdf/send/bulk/email/';

//export const buyertoken = '/api/v1/admin/buyer/token';
export const deleteuser = '/api/v1/buyer/user/remove/';

//report
export const saleitemReport = '/api/v1/report/sale/item/list';
export const bidderReport = '/api/v1/report/bidder/list';
export const pickupReport = '/api/v1/report/pickup/list';
export const saleReport = '/api/v1/report/sale/list';
export const itemReport = '/api/v1/report/item/list';
export const adminReport = '/api/v1/admin/report/';
export const sellerReport = '/api/v1/seller/report/';
export const upcomingReport = '/api/v1/report/upcoming/sale/list'


export const messagelistbyitem = '/api/v1/message/groupedby/item/list';
export const messagelist = '/api/v1/message/list';
export const messagereplyadd = '/api/v1/message/reply/add';
export const messageupdate = '/api/v1/message/update';

export const contentText = "/api/v1/webcontent/list/";

// front end path
export const salesDashboard = '/sales/dashboard';
export const items = '/sales/items';
export const itemStatusUpdate = '/api/v1/item/status/update';

// default Password
//export const defaultPassword = '/api/v1/buyer/user/generate/default/password'

export const deleteMessage = "/api/v1/message/delete";
export const voidInvoice = "/api/v1/invoice/update/void";
export const itemsNoteCount = "/api/v1/item/inot/count";
export const salesNoteCount = "/api/v1/sale/note/count"

// process payment
export const processPayment = "/api/v1/vault/auto/debit";
export const sellerToPaymentToken = "/api/v1/seller/payment/token";
export const paymentStatusSseEvent = "/api/v1/payment/sse/triggerevent/";
export const paymentLocationSseEvent = "/api/v1/payment/sse/addlocation/";
export const singleProcessPayment = "/api/v1/vault/auto/debit/invoice";
export const invoicePendingProcessPayment = "/api/v1/vault/auto/debit/invoice/pending/amount";

// SSE Event for pending count in sidebar
export const bidsSseEvent = "/api/v1/sse/triggerevent/";
export const taxExemptCount = "/api/v1/sse/addlocation/";

// sale end time
export const saleDateValidity = "/api/v1/sale/time/check/validity";

// Sse
export const saleDashboardSseEvent = "api/v1/alerts/sse/sale/dashboard/"
// Buyer shipping address
export const buyerShippingAddress = "/api/v1/address/buyer/list";

// City/State check
export const stateCityCheck = "/api/v1/address/check/city";

export const paymentMethodCount = "/api/v1/payment/vaultcard/count/";

// Condition data
export const conditionData = "/api/v1/static/content/view/condition";

//pickuptime data
export const pickuptime = "/api/v1/sale/update/pickuptime";

//MIR
export const MirList = "/api/v1/seel/contract/list";

//quate list
export const quote = "/api/v1/seel/quote/list";

// Fullfillment
export const fullFillmentList = "/api/v1/fullfillment/list";
export const fullFillmentAdd = "/api/v1/fullfillment/add";
export const fullFillmentUpdate = "/api/v1/fullfillment/update";
export const fullfillmentPendingList = "/api/v1/fullfillment/pending/list";
