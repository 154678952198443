import { Component, createRef } from 'react';
import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';

import './Fullfillment.scss';
import Datatable from 'shared/datatable';
import { SelectDropdown } from 'shared/dropdown/Dropdown';
import axios from 'axios';
import { getSellerHeaders, getSellerUrl } from 'shared/http';
import { fullFillmentAdd, fullFillmentList, fullFillmentUpdate, fullfillmentPendingList } from 'shared/settings/apiconfig';
import { ToasterMessage } from 'shared/toast/toast';
import Validators from 'shared/validators';
import { capitalizeFirstLetter } from 'shared/utils';

/**
 * this page is used to create fullfillment
 * 
 * @param props
 * 
 * 
 * @return html to render fullfillment view
 * 
**/

export class Fullfillment extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fullfillment: {
                trackingnumber: { value: '', error: '', valid: false, validators: [Validators.required()] },
                carrier: { value: '', error: '', valid: false, validators: [Validators.required()] },
                selectedItemOptions: { value: [], error: '', valid: false, validators: [Validators.required()] },
                shipmentstatus: { value: 'Shipped', error: '', valid: true, validators: [Validators.required()] },
                notes: {value: '', error: '', valid: true, validators: [Validators.maxLength(100)]}
            },
            irm: '',
            selectedItemDisplay: '',
            id: '',
            buyerid: this.props?.location?.state?.buyerid,
            shippingCheckboxData: [],
            fullfillmentModal: false,
            loading: false,
            searchData: true,
            tableData: [],
            fullfillmentMessage: { show: false, type: '', message: '' },
            formValid: false
        }

        this.config = {
            name: 'fullfillment',
            url: fullFillmentList,
            params: {},
            columns: [
                {
                    name: '', property: 'id', columnType: 'actions', actions: ['edit'], customHeader: <> </>, width: '100px',
                    actionEvents: { edit: (row) => { this.openFullfillmentModal(row) } }
                },
                { name: 'Item Title', property: 'itemtitle', sort: false, width: '200px' },
                { name: 'Tracking Number', property: 'trackingnumber', sort: false, width: '150px' },
                { name: 'Carrier', property: 'carrier', sort: false, width: '150px' },
                { name: 'Item Receipt Method', property: 'irm', sort: false, width: '100px', columnType: 'custom', customColumn: this.customirm}
            ],
            field: ["id", "itemid", "buyrid", "orderid", "invoiceid", "fullfillmentid", "fullfillmentresponse", "fullfillmentstatus", "carrier",
                "trackingnumber", "shipmentstatus", "trackingDetails", "itemtitle", "irm","notes","saleid","saletitle"],
            defaultFilter: [{ field: "invoiceid", value: this.props.match.params.invoiceId, op: "=", join: "AND" }],
            sort: [{ field: 'id', direction: 'desc' }],
            pagination: true,
            ignoreRowBackground: true
        };

        this.dataTable = createRef();
    }

    componentDidMount() {
        this.getfulfillmentPendingList();
    }
    customirm = (value) => {
        const irmObject = JSON.parse(value.irm);
        if (typeof irmObject === 'object' && irmObject !== null) {
            const irm = Object.keys(irmObject).filter(key => irmObject[key] === true);
            return capitalizeFirstLetter(irm[0]) ;
        }
    }

    getfulfillmentPendingList = () => {
        let data = {
            filter: [
                {field: "invoiceid", value: this.props.match.params.invoiceId, op: "=", join: "AND"}
            ]
        }
        axios.post(getSellerUrl(fullfillmentPendingList), JSON.stringify(data), getSellerHeaders()).then(response => {
            if(response.data.status == "success") {
                let pendingList = response.data.data;
                if(pendingList.length > 0) {
                    let itemids = [];
                    pendingList.map((elem) => {
                        itemids.push({ id: elem.itemid, option: elem.itemtitle });
                    })
                    this.setState({
                        shippingCheckboxData: [...itemids]
                    })
                } else {
                    this.setState({
                        shippingCheckboxData: []
                    })
                }
            }
        }).catch(error => {
            this.setState({
                shippingCheckboxData: []
            })
        })
    }

    /* check validation for input changes*/
    inputChange = (event, type) => {
        let inputError = '';
        let eventValue = event.target.value;

        for (let validator of this.state.fullfillment[event.target.name].validators) {
            inputError = validator(eventValue);
            if (inputError) break;
        }

        this.setState({
            ...this.state,
            fullfillment: {
                ...this.state.fullfillment,
                [type]: {
                    ...this.state.fullfillment[type],
                    value: eventValue,
                    error: inputError,
                    valid: !Boolean(inputError)
                }
            }
        }, () => {
            /* check for form validation */
            if (inputError)
                this.setState({
                    ...this.state,
                    formValid: false
                });
            else {
                this.checkFormValidity();
            }
        })
    }  

    checkFormValidity = () => {
        let valid = true;
        for (let control of Object.keys(this.state.fullfillment)) {
            if (!this.state.fullfillment[control].valid) {
                valid = false;
                break;
            }
        }

        this.setState({
            ...this.state,
            formValid: valid
        });
    }

    validateFullFillmentFormSave = (isUpdate=false) => {
        this.setState({ loading: true });

        let valid = true;
        let inputError = '';
        let stateData = this.state.fullfillment;
        for (let control of Object.keys(stateData)) {
            for (let validator of stateData[control].validators) {
                inputError = validator(stateData[control].value);
                if (inputError) {
                    valid = false;
                    break;
                }
            }
            stateData[control]['error'] = inputError;
        }
        this.setState({
            ...this.state,
            formValid: valid,
            fullfillment: stateData
        }, () => {
            // Update
            if (isUpdate) {
                this.updateFullfillment();
            } else { 
                // Create
                this.createFullfillment();
            }
        });
    }

    /* Save the fullfillment modal form */
    createFullfillment = () => {
        if (this.state.formValid) {
            let lineItemData = [];
            this.state.fullfillment.selectedItemOptions.value.map((elem) => {
                lineItemData.push({
                    "line_item_id": elem,
                    "quantity": 1
                })
            })
            let data = {
                "data": {
                    "invoiceid": this.props.match.params.invoiceId,
                    "trackingnumber": this.state.fullfillment.trackingnumber.value,
                    "carrier": this.state.fullfillment.carrier.value,
                    "itemid": lineItemData,
                    "buyrid": this.state.buyerid,
                    "shipmentstatus": 'Shipped',
                    "notes": this.state.fullfillment.notes.value,
                    "irm": { "pickup": false, "delivery": false, "shipping": true }
                }
            };
            axios.post(getSellerUrl(fullFillmentAdd), JSON.stringify(data), getSellerHeaders())
                .then(response => {
                    if (response.data.status === 'success') {
                        this.dataTable.current.getTableData();
                        this.getfulfillmentPendingList();
                        this.setState({
                            loading: false,
                            fullfillmentMessage: { show: true, type: 'success', message: 'Data saved successfully' }
                        }, () => {
                            setTimeout(() => {
                                this.setState({
                                    fullfillment: {
                                        ...this.state.fullfillment,
                                        trackingnumber: {
                                            ...this.state.fullfillment.trackingnumber,
                                            value: ''
                                        },
                                        carrier: {
                                            ...this.state.fullfillment.carrier,
                                            value: ''
                                        },
                                        selectedItemOptions: {
                                            ...this.state.fullfillment.selectedItemOptions,
                                            value: []
                                        },
                                        shipmentstatus: {
                                            ...this.state.fullfillment.shipmentstatus,
                                            value: this.state.fullfillment.shipmentstatus.value
                                        },
                                        notes: {
                                            ...this.state.fullfillment.notes,
                                            value: ''
                                        },
                                    },
                                    selectedItemDisplay: '',
                                    buyrid: '',
                                    formValid: false,
                                    fullfillmentMessage: { show: false, type: '', message: '' }
                                })
                            }, 2000);
                        })
                    }
                })
                .catch(error => {
                    this.setState({
                        loading: false
                    })
                })
        }
    }  

    fullfillmentOption = (itemids) => {
        this.setState({
            ...this.state,
            fullfillment: {
                ...this.state.fullfillment,
                selectedItemOptions: {
                    ...this.state.fullfillment.selectedItemOptions,
                    value: [...itemids],
                    error: itemids.length == 0 ? "Please select itemids" : "",
                    valid: itemids.length == 0 ? false : true
                },
            }
        }, () => {
            this.checkFormValidity();
        })
    }

    openFullfillmentModal = (row) => {
        this.setState({
            fullfillmentModal: true,
            fullfillment: {
                ...this.state.fullfillment,
                trackingnumber: { 
                    ...this.state.fullfillment.trackingnumber,
                    value: row.trackingnumber 
                },
                carrier: { 
                    ...this.state.fullfillment.carrier,
                    value: row.carrier 
                },
                selectedItemOptions: {
                    ...this.state.fullfillment.selectedItemOptions,
                    value: [ row.itemid]
                },
                shipmentstatus: { 
                    ...this.state.fullfillment.shipmentstatus,
                    value: row.shipmentstatus 
                },
                notes: { 
                    ...this.state.fullfillment.notes,
                    value:row.notes 
                },
            },
            id: row.id,
            buyrid: row.buyrid,
            selectedItemDisplay: row.itemid
        })
    }

    updateFullfillment = () => {
        let lineItemData = [];
        this.state.fullfillment.selectedItemOptions.value.map((elem) => {
            lineItemData.push({
                "line_item_id": elem,
                "quantity": 1
            })
        })
        if (this.state.formValid) {
            let data = {
                "data": {
                    "invoiceid": this.props.match.params.invoiceId,
                    "trackingnumber": this.state.fullfillment.trackingnumber.value,
                    "carrier": this.state.fullfillment.carrier.value,
                    "itemid": lineItemData,
                    "id": this.state.id,
                    "buyrid": this.state.buyerid,
                    "shipmentstatus": 'Shipped',
                    "notes": this.state.fullfillment.notes.value,
                    "irm": { "pickup": false, "delivery": false, "shipping": true }
                }
            };

            axios.put(getSellerUrl(fullFillmentUpdate), JSON.stringify(data), getSellerHeaders())
                .then(response => {
                    if (response.data.status === 'success') {
                        this.dataTable.current.getTableData();
                        this.setState({
                            loading: false,
                            fullfillmentMessage: { show: true, type: 'success', message: 'Data saved successfully' },

                        }, () => {
                            setTimeout(() => {
                                this.setState({
                                    fullfillment: {
                                        ...this.state.fullfillment,
                                        trackingnumber: {
                                            ...this.state.fullfillment.trackingnumber,
                                            value: ''
                                        },
                                        carrier: {
                                            ...this.state.fullfillment.carrier,
                                            value: ''
                                        },
                                        selectedItemOptions: {
                                            ...this.state.fullfillment.selectedItemOptions,
                                            value: []
                                        },
                                        shipmentstatus: {
                                            ...this.state.fullfillment.shipmentstatus,
                                            value: this.state.fullfillment.shipmentstatus.value
                                        },
                                        notes: {
                                            ...this.state.fullfillment.notes,
                                            value: ''
                                        },
                                    },
                                    selectedItemDisplay: '',
                                    fullfillmentModal: false,
                                    fullfillmentMessage: { show: false, type: '', message: '' }
                                })
                            }, 2000);
                        })
                    }
                })
                .catch(error => {
                    this.setState({
                        fullfillmentMessage: { show: true, type: 'danger', message: 'Data not saved successfully' },
                        loading: false
                    }, () => {
                        setTimeout(() => {
                            this.setState({
                                fullfillmentMessage: { show: false, type: '', message: '' }
                            })
                        }, 2000);
                    })
                })
        }
    }
    

    resetFullfillment = () => {
        this.setState({
            fullfillment: {
                ...this.state.fullfillment,
                trackingnumber: {
                    ...this.state.fullfillment.trackingnumber,
                    value: '',
                    error: ''
                },
                carrier: {
                    ...this.state.fullfillment.carrier,
                    value: '',
                    error: ''
                },
                selectedItemOptions: {
                    ...this.state.fullfillment.selectedItemOptions,
                    value: [],
                    error: ''
                },
                shipmentstatus: {
                    ...this.state.fullfillment.shipmentstatus,
                    value: this.state.fullfillment.shipmentstatus.value,
                    error: ''
                },
                notes: {
                    ...this.state.fullfillment.notes,
                    value: '',
                    error: ''
                },
            },
            selectedItemDisplay: '',
            fullfillmentModal: false,
            fullfillmentMessage: { show: false, type: '', message: '' }
        });
    }

    updateTableData = (event) => {
        this.setState({
            ...this.state,
            tableData: event
        });
    }

    navigateToDashboard = () => {
        if(this.props.history) {
            this.props.history.goBack();
        } else {
            this.props.history.push(`/invoice/view/${this.props.match.params.invoiceId}`);
        }
    }

    clearFulfillmentOption = () => {
        this.setState({
            fullfillment: {
                ...this.state.fullfillment,
                selectedItemOptions: {
                    ...this.state.fullfillment.selectedItemOptions,
                    value: [],
                    valid: false,
                    error: ""
                }
            },
            formValid: false,
            selectedItemDisplay: ''
        })
    }

    render() {
        return (
            <div style={{ overflow: "auto", overflowY: "overlay" }}>
                <div id="backbutton" style={{ borderBottom: "1px solid #b6b6b6", padding: "1%" }}>
                    <span onClick={this.navigateToDashboard} className="clickable">
                        <i className="fa fa-chevron-circle-left fa-lg text-back rounded-circle"></i>
                        <span style={{ paddingLeft: "1em" }}>Back</span>
                    </span>
                </div>
                <div className="fullfillment-create">
                    <div className="fulliment-list-create">
                        <Form className="fullfillment-list-font">
                            <div className="fullfillment-create-container">
                                <Row>
                                    <Col>
                                        <label>Item Receipt Method</label>
                                        <Form.Control type="text" name="irm" value="Shipping" disabled />
                                    </Col>
                                    <Col style={{ pointerEvents : Object.keys(this.state.shippingCheckboxData).length == 0 ? "none" : '' }}>
                                        <label>Select Items *</label>
                                        <SelectDropdown multiple placeholder="Select item" id="selectedItemOption" value={this.state.fullfillment.selectedItemOptions.value} onChange={(event) => { this.fullfillmentOption(event) }} onClear={() => { this.clearFulfillmentOption() }}
                                            data={this.state.shippingCheckboxData} required/>
                                        {this.state.shippingCheckboxData.length == 0 ? <span className='text-danger'>No items available</span> : ''}
                                        <span className='text-danger'>
                                            {
                                                this.state.fullfillment.selectedItemOptions.error && this.state.fullfillment.selectedItemOptions.error.length ? <small className="error">{this.state.fullfillment.selectedItemOptions.error}</small> : null
                                            }
                                        </span>
                                    </Col>
                                    <Col>
                                        <label>Tracking Number *</label>
                                        <Form.Control type="text" id="trackingnumber" name="trackingnumber" value={this.state.fullfillment.trackingnumber.value} required onChange={(event) => this.inputChange(event, "trackingnumber")}  />
                                        <span className='text-danger'>
                                            {
                                                this.state.fullfillment.trackingnumber.error && this.state.fullfillment.trackingnumber.error.length ? <small className="error">{this.state.fullfillment.trackingnumber.error}</small> : null
                                            }
                                        </span>
                                    </Col>
                                    <Col>
                                        <label>Carrier *</label>
                                        <Form.Control type="text" id="carrier" name="carrier" value={this.state.fullfillment.carrier.value} required onChange={(event) => this.inputChange(event, "carrier")} />
                                        <span className='text-danger'>
                                            {
                                                this.state.fullfillment.carrier.error && this.state.fullfillment.carrier.error.length ? <small className="error">{this.state.fullfillment.carrier.error}</small> : null
                                            }
                                        </span>
                                    </Col>
                                </Row>
                                <Row>
                                    {/* <Col>
                                        <label>Shipping Status *</label>
                                        <Form.Control as="select" id="shipmentstatus" name="shipmentstatus" value={this.state.fullfillment.shipmentstatus.value} className={this.state.fullfillment.shipmentstatus.value ? 'form-control-active' : ''} placeholder=" " required onChange={(event) => this.inputChange(event, "shipmentstatus")}>
                                            <option selected value={''} disabled={this.state.fullfillment.shipmentstatus.value ? true : false }>Select Status</option>
                                            <option value="Shipped">Shipped</option>
                                            <option value="Delivered">Delivered</option>
                                        </Form.Control>
                                        <span className='text-danger'>
                                            {
                                                this.state.fullfillment.shipmentstatus.error && this.state.fullfillment.shipmentstatus.error.length ? <small className="error">{this.state.fullfillment.shipmentstatus.error}</small> : null
                                            }
                                        </span>
                                    </Col> */}
                                    <Col>
                                        <label>Notes</label>
                                        <Form.Control as="textarea" name="notes" id="notes" value={ this.state.fullfillment.notes.value} onChange={(event) => this.inputChange(event, "notes")} />
                                        <span className='text-danger'>
                                            {
                                                this.state.fullfillment.notes.error && this.state.fullfillment.notes.error.length ? <small className="error">{this.state.fullfillment.notes.error}</small> : null
                                            }
                                        </span>
                                    </Col>
                                    <Col></Col>
                                    <Col></Col>
                                </Row>
                                <div className='d-flex align-items-center justify-content-center text-center'>
                                    {
                                        this.state.fullfillmentMessage.show ?
                                            <ToasterMessage type={this.state.fullfillmentMessage.type} message={this.state.fullfillmentMessage.message} className="alert-handle mt-2"></ToasterMessage>
                                            : null
                                    }
                                </div>
                                <Row className="buttonClass">
                                    <Col>
                                        <div className="d-flex align-items-center justify-content-end">
                                            <Button variant="seller-search" onClick={() => this.validateFullFillmentFormSave(false)} disabled={this.state.shippingCheckboxData.length == 0 || !this.state.formValid}>Create {' '} {this.state.loading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : null}</Button>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Form>

                    </div>
                </div>
                <div className="fullfillment-list">
                    <Datatable {...this.props} config={{ ...this.config }} ref={this.dataTable} search={this.state.searchData ? { search: {}, searchData: this.state.searchData } : null} onDataUpdate={this.updateTableData}></Datatable>
                </div>

                <Modal centered show={this.state.fullfillmentModal} size='xl' id="fullfillmentModal">
                    <Modal.Body>
                        <h5 className="text-secondary">Update Fulfillment</h5>
                        <Form>
                            <div className="float-control">
                                <Form.Control type="text" id="irm" name="irm" placeholder=" " value={"Shipping"} className='form-control-active' disabled />
                                <label htmlFor="irm" className="required">Item Receipt Method</label>
                            </div>
                            <div className="float-control">
                                <div className="text">
                                    {
                                        this.state.fullfillment.selectedItemOptions.error && this.state.fullfillment.selectedItemOptions.error.length ? <small className="error">{this.state.fullfillment.selectedItemOptions.error}</small> : null
                                    }
                                </div>
                                <Form.Control type="number" id='itemid' name="itemid" value={this.state.selectedItemDisplay} disabled />
                                <label htmlFor="itemid" className="required">Item Id</label>
                            </div>
                            <div className="float-control">
                                <div className="text">
                                    {
                                        this.state.fullfillment.trackingnumber.error && this.state.fullfillment.trackingnumber.error.length ? <small className="error">{this.state.fullfillment.trackingnumber.error}</small> : null
                                    }
                                </div>
                                <Form.Control type="text" id="trackingnumber" name="trackingnumber" placeholder=" " value={this.state.fullfillment.trackingnumber.value} className='form-control-active' onChange={(event) => this.inputChange(event, 'trackingnumber')} />
                                <label htmlFor="trackingnumber" className="required">Tracking Number</label>
                            </div>
                            <div className="float-control">
                                <div className="text">
                                    {
                                        this.state.fullfillment.carrier.error && this.state.fullfillment.carrier.error.length ? <small className="error">{this.state.fullfillment.carrier.error}</small> : null
                                    }
                                </div>
                                <Form.Control type="text" id="carrier" name="carrier" placeholder=" " value={this.state.fullfillment.carrier.value} className='form-control-active' onChange={(event) => this.inputChange(event, 'carrier')} />
                                <label htmlFor="carrier" className="required">Carrier</label>
                            </div>
                            {/* <div className='float-control'>
                                <Form.Control as="select" id="shipmentstatus" name="shipmentstatus" value={this.state.fullfillment.shipmentstatus.value} className={this.state.fullfillment.shipmentstatus.value ? 'form-control-active' : ''} placeholder=" " required onChange={(event) => this.inputChange(event, "shipmentstatus")}>
                                    <option selected value={''} disabled={this.state.fullfillment.shipmentstatus.value ? true : false }>Select Status</option>
                                    <option value="Shipped">Shipped</option>
                                    <option value="Delivered">Delivered</option>
                                </Form.Control>
                                <Form.Label htmlFor="shipmentstatus" className="required">Shipping Status</Form.Label>
                            </div> */}
                            <div className="float-control">
                            <div className="text">
                                    {
                                        this.state.fullfillment.notes.error && this.state.fullfillment.notes.error.length ? <small className="error">{this.state.fullfillment.notes.error}</small> : null
                                    }
                                </div>
                                <Form.Control as="textarea" id="notes" name="notes" placeholder=" " value={this.state.fullfillment.notes.value} className='form-control-active' onChange={(event) => this.inputChange(event, 'notes')} />
                                <label htmlFor="notes">Notes</label>
                            </div>
                            <div className='d-flex align-items-center justify-content-center text-center'>
                                {
                                    this.state.fullfillmentMessage.show ?
                                        <ToasterMessage type={this.state.fullfillmentMessage.type} message={this.state.fullfillmentMessage.message} className="alert-handle mt-2"></ToasterMessage>
                                        : null
                                }
                            </div>
                            <div className="mt-2">
                                <div className="d-flex justify-content-center">
                                    <Button onClick={() => this.resetFullfillment()} className="btn-white">Cancel</Button>
                                    <Button onClick={() => this.validateFullFillmentFormSave(true)} className="btn-secondary ml-2" >Save {' '} {this.state.loading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : null}</Button>
                                </div>
                            </div>
                        </Form>
                    </Modal.Body>
                </Modal>

            </div>
        );
    }
}